const LINKS = [{
        href: '/about',
        label: 'About',
        background: false,
        target: ''
    },
    {
        href: '/contact',
        label: 'Contact',
        background: false,
        target: ''
    },
    {
        href: '/tom-bola',
        label: 'TOM-BOLA',
        background: true,
        target: ""
    },
]

export { LINKS }