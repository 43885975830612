import React, { useEffect, useState } from 'react';
import './styles/general.css'
import axios from 'axios'

import { ErrorMessage, Field, Form, Formik, } from 'formik'
import * as Yup from 'yup'

function ContactSection(props) {
    const [state, setState] = useState({
        message: {
            display: false,
            type: '',
            content: ''
        }
    })
    const [formData] = useState({
        email: "",
        name: "",
        phone: "",
        message: ""
    })

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .email(props.translate('home.contact.form.invalid.email'))
            .required(props.translate('home.contact.form.required.email')),
        name: Yup.string()
            .required(props.translate('home.contact.form.required.name')),

    })

    const [scrollPosition, setScrollPosition] = useState(0)

    useEffect(() => {
        window.addEventListener("scroll", setScroll);
        // eslint-disable-next-line 
    }, [])



    function setScroll() {
        let cardExample = document.querySelector('#contact-card-section-home')

        if (cardExample && cardExample.getBoundingClientRect().top < (window.innerHeight) && !scrollPosition) {
            setScrollPosition(1)

        }
    }
    function hideMessage() {
        setState({ ...state, message: { ...state.message, display: false } });
    }


    function handleSubmitFormData(values, actions) {
        console.log('values: ', values);

        axios.post('/api/v1/contactMail', values).then((res) => {
            if (res.data === "Success") {
                setState({
                    ...state,
                    message: {
                        display: true,
                        type: 'success',
                        content: props.translate('home.contact.form.message.sent.success')
                    }
                })
                actions.setFieldValue('email', ' ');
                actions.setFieldValue('name', '');
                actions.setTouched('email', false)
                actions.setTouched('name', false)
                actions.setFieldValue('phone', '');
                actions.setFieldValue('message', '');
            }
            setTimeout(() => { hideMessage(); }, 8000);
        }).catch(err => {
            setState({
                ...state,
                message: {
                    display: true,
                    type: 'danger',
                    content: props.translate('home.contact.form.message.sent.fail')
                }
            })
        })




    }

    return (
        <div className='contact-form-body-wrap'>
            <div id='contact-card-section-home' >
                <div className=''>
                    <div className='contact-section-card-header'>
                        <h1>{props.translate('home.contact.form.main.title')}</h1>
                    </div>
                </div>
                <div className='container'>
                    <div className='col-12 contact-section-text-box'>
                        <div className='d-flex justify-content-center'>
                            <div className='col-12 col-md-12'>
                                <h3>
                                    {props.translate('home.contact.form.title')}
                                </h3>
                                <p>
                                    {props.translate('home.contact.form.subtitle')}
                                </p>

                                <div>
                                    <Formik
                                        initialValues={formData}
                                        validationSchema={validationSchema}
                                        onSubmit={handleSubmitFormData}
                                    >
                                        <Form>
                                            <div className="form-group row">
                                                <label htmlFor="email" className="col-12 col-form-label">
                                                    {props.translate('home.contact.form.label.email')}
                                                </label>
                                                <div className="col-12">
                                                    <div className="input-group">
                                                        <div className="input-group-prepend">
                                                            <div className="input-group-text">
                                                                <i className="fa fa-at" />
                                                            </div>
                                                        </div>
                                                        <Field id="email" name="email" type="text" className="form-control" />
                                                        <span className='text-danger'>
                                                            <ErrorMessage name="email" className="invalid-feedback" />
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>



                                            <div className="form-group row">
                                                <label htmlFor="name" className="col-12 col-form-label">
                                                    {props.translate('home.contact.form.label.name')}
                                                </label>
                                                <div className="col-12">
                                                    <div className="input-group">
                                                        <div className="input-group-prepend">
                                                            <div className="input-group-text">
                                                                <i className="fa fa-user" />
                                                            </div>
                                                        </div>
                                                        <Field id="name" name="name" type="text" className="form-control" />
                                                        <span className='text-danger'>
                                                            <ErrorMessage name="name" className="invalid-feedback" />
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label htmlFor="phone" className="col-12 col-form-label">
                                                    {props.translate('home.contact.form.label.phone')}
                                                </label>
                                                <div className="col-12">
                                                    <div className="input-group">
                                                        <div className="input-group-prepend">
                                                            <div className="input-group-text">
                                                                <i className="fa fa-phone" />
                                                            </div>
                                                        </div>
                                                        <Field id="phone" name="phone" type="text" className="form-control" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label htmlFor="message" className="col-12 col-form-label">
                                                    {props.translate('home.contact.form.label.message')}
                                                </label>
                                                <div className="col-12">
                                                    <Field component={'textarea'} id="message" name="message" cols={40} rows={5} className="form-control" />
                                                </div>
                                            </div>
                                            <div hidden={!state.message.display}>
                                                <div className={`alert alert-${state.message.type}`} role="alert">
                                                    {state.message.content}
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <div className=" col-12">
                                                    <button type='submit' className="btn btn-primary btn-block btn-lg">
                                                        {props.translate('home.contact.form.button.text')}
                                                    </button>
                                                </div>
                                            </div>
                                        </Form>
                                    </Formik>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default ContactSection;