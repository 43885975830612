import React from 'react';
import './styles/mobile.css'
import './styles/desktop.css'
import './styles/responsiveBackground.css'
function Banner(props) {
    return (
        <div className='newacy-banner'>
            <div className='newacy-banner-wrapper'>
                <div className='newacy-banner-background-color'>
                    <div className='newacy-banner-background-image'>
                        <div className='newacy-banner-background-image-filter'>
                            <div className='container'>

                                <div className='col-12 col-lg-10 offset-lg-1'>
                                    <div className='newacy-banner-text-content-wrap'>
                                        <div className='newacy-banner-text-content'>
                                            <h1>{props.translate('home.banner.title')}</h1>
                                            <h3>{props.translate('home.banner.subtitle')}</h3>

                                            <a href={props.translate('home.banner.button.1.link')}>
                                                <button className='btn btn-danger btn-lg'>
                                                    {props.translate('home.banner.button.1.text')}
                                                </button>
                                            </a>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Banner;