import React from 'react';
import Navbar from '../mainComponents/NavBar/Navbar'
import { Markup } from 'interweave';
import Footer from '../mainComponents/Footer/Footer'
import './styles/general.css'
import Contact from '../mainComponents/Contact/Contact'
function About(props) {
    return (
        <>
            <Navbar logoTheme='white' />
            <div className='newacy-about-banner'>
                <img srcSet=" /images/about/bannerw_200.jpg 200w, /images/about/bannerw_519.jpg 519w, /images/about/bannerw_745.jpg 745w, /images/about/bannerw_909.jpg 909w,/images/about/bannerw_1269.jpg 1269w,/images/about/bannerw_1400.jpg 1400w" src="/images/about/bannerw_1400.jpg" alt="" />
                <h1 className='newacy-about-banner-title'>{props.translate('about.page.banner.title')} </h1>
            </div>


            <div className='about-page-content'>
                <div className='about-page-content-title-section'>
                    <div className='d-flex justify-content-center'>
                        <div className='col-6 col-md-4 col-lg-2'>
                            <img src="/logos/newacy-red.svg" width='100%' alt="" />
                        </div>
                    </div>
                    <h1 className='about-page-title'> {props.translate('about.page.content.title')}  </h1>
                    <h3 className='about-page-subtitle'> {props.translate('about.page.content.subtitle')}  </h3>
                </div>
                <div className='container about-page-content-description-section'>
                    <Markup content={props.translate('about.page.content.description')} />

                </div>
            </div>
            <Contact  {...props} />

            <Footer />
        </>
    );
}

export default About;