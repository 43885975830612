import React from 'react';
import Card from './components/Cards'
import './styles/cards.css'
import './styles/general.css'


function Section1(props) {
    return (
        <div id='what-we-do' className='newacy-section1'>
            <div className='newacy-title-section-title-wrap'>
               <div className='container'>
                    <div className='newacy-section-title-red'>
                        <h1>{props.translate('home.section1.title')}</h1>
    
                    </div>
                    <div className='newacy-section-subtitle'>
                        <h5>{props.translate('home.section1.subtitle')}</h5>
                    </div>
               </div>
            </div>

            <div className='container'>
                <div className='row'>
                    <div className='col-12 col-md-4  newacy-section1-card-wrapper' >
                        <Card
                            icon='/icons/section1/card1.png'
                            title={props.translate('home.section1.card1.title')}
                            description={props.translate('home.section1.card1.description')}
                        />
                    </div>
                    <div className='col-12 col-md-4 newacy-section1-card-wrapper' >
                        <Card
                            icon='/icons/section1/card2.png'
                            title={props.translate('home.section1.card2.title')}
                            description={props.translate('home.section1.card2.description')}
                            theme={'blue'}
                        />
                    </div>
                    <div className='col-12 col-md-4 newacy-section1-card-wrapper'>
                        <Card
                            icon='/icons/section1/card3.png'
                            title={props.translate('home.section1.card3.title')}
                            description={props.translate('home.section1.card3.description')}

                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Section1;