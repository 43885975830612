import React from 'react';
import Img404 from './assets/404.svg'
import { Link } from 'react-router-i18n';
import './styles/general.css'

function NotFound(props) {
    return (
        <div className='page-404 '>
            <div className='d-flex justify-content-center'>
                <div className='col-8'>
                    <img className='page404-img' src={Img404} alt="" />
                    <h1 className='page404-text'>
                        {props.translate('404.text')}
                    </h1>
                    <div className='page404-link'>
                        <Link to={props.translate('404.link.value')}>{props.translate('404.link.text')}</Link>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default NotFound;