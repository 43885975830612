import React from 'react';
import Navbar from '../mainComponents/NavBar/Navbar'
import { Markup } from 'interweave';
import Footer from '../mainComponents/Footer/Footer'
import './styles/general.css'
import TomBolaLogo from './assets/tom-bola-logo.svg'


function About(props) {
    return (
        <>
            <Navbar logoTheme='white' />
            <div className='newacy-tom-bola-banner'>
            <img sizes="(max-width: 1400px) 100vw, 1400px" srcSet="
/images/tom-bola/background_kvzlcm_c_scale,w_200.jpg 200w,
/images/tom-bola/background_kvzlcm_c_scale,w_437.jpg 437w,
/images/tom-bola/background_kvzlcm_c_scale,w_652.jpg 652w,
/images/tom-bola/background_kvzlcm_c_scale,w_855.jpg 855w,
/images/tom-bola/background_kvzlcm_c_scale,w_896.jpg 896w,
/images/tom-bola/background_kvzlcm_c_scale,w_1231.jpg 1231w,
/images/tom-bola/background_kvzlcm_c_scale,w_1280.jpg 1280w,
/images/tom-bola/background_kvzlcm_c_scale,w_1388.jpg 1388w,
/images/tom-bola/background_kvzlcm_c_scale,w_1400.jpg 1400w" src="/imges/tom-bola/background_kvzlcm_c_scale,w_1400.jpg" alt="" />
                <h1 className='newacy-tom-bola-banner-title'>{props.translate('tom.bola.page.banner.title')} </h1>
            </div>


            <div className='tom-bola-page-content'>
                <div className='tom-bola-page-content-title-section'>
                    <div className='d-flex justify-content-center'>

                        <div className='col-4 col-md-4 col-lg-2 newacy-tom-bola-banner-img-title'>
                            <img width='100%' src={TomBolaLogo} alt="" />
                        </div>
                    </div>

                    <div className='container'>
                        <h1 className='tom-bola-page-title'> {props.translate('tom.bola.page.content.title')}  </h1>

                        <Markup content={props.translate('tom.bola.page.content.subtitle')} />

                    </div>
                </div>
                <div className='container tom-bola-page-content-description-section'>
                    <Markup content={props.translate('tom.bola.page.content.description')} />
                </div>
                <div className='container tom-bola-page-cta-to-site'>

                    <a href='https://www.tom-bola.com/' target='_blank' alt='tom-bola' rel='noreferrer'>


                        {props.translate('tom.bola.page.content.cta.title')}

                    </a>
                </div>
            </div>

            <Footer />
        </>
    );
}

export default About;