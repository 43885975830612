import React from 'react';
import './styles/general.css'


function Footer(props) {
    return (
        <div className='newacy-footer'>
        <footer id="footer" className=" modern">
            <div className="container">
                <div className="row d-flex justify-content">
                    <div className="col-12 col-md-3">
                        <div className="footer-logo">
                            <img alt="Newacy Consulting" className=" lazyloaded" src="/logos/newacy-dark.png" 
                            style={{width:'100%', maxHeight:"100%"}} />
                        </div>
                    </div>

                </div>
                <div className="footer-bottom-wrap">
                    <div className="container">
                        <div className='col-12'>
                            <a href='https://newacy.com/'>© Newacy Limited 2021.</a>
                        </div>
                    </div>
                    <div className='col-12'>
                        <p><a href="mailto:sales@newacy.com">sales@newacy.com</a></p>

                    </div>
                    <div className="col-12">
                        <div className="socials">
                            {// eslint-disable-next-line
                            <a href="https://www.linkedin.com/company/newacy-limited" className="fa fa-linkedin" />
                             }
                        </div>
                    </div>
                </div>
            </div>
        </footer>

    </div >
    );
}

export default Footer;