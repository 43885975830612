import React from 'react';

function Card(props) {

    const Image = () => {
        return (
            <div className='col-12  col-md-4  newacy-section2-card-image-wrap'>
                <img src={props.icon} alt="" />
            </div>
        )
    }



    const Text = () => {
        return (
            <div className='col-12  col-md-8  newacy-section2-card-text-wrap'>
                <div className='newacy-section2-card-text-title'>
                    <h1>{props.title}</h1>
                </div>
                <div className='newacy-section2-card-text-description'>
                    <p>{props.description}</p>
                </div>

            </div>
        )
    }

    return (
        <div className={`newacy-section2-card`}>
            <div className='row'>

                {
                    props.sideRight ?
                        <>
                            <Text />
                            <Image />
                        </> :
                        <>
                            <Image />
                            <Text />
                        </>
                }
            </div>

        </div>
    );
}

export default Card;