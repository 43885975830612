import React from 'react';

function Section5(props) {
    return (
        <div>
            <div className='newacy-title-section-title-wrap'>
                <div className='newacy-section-title'>
                    <h1>{props.translate('home.section5.title')}</h1>

                </div>
                <div className='newacy-section-title'>
                    <h5>{props.translate('home.section5.subtitle')}</h5>
                </div>
            </div>

            <div className='container'>
                <img src="/images/section5/bg.png" alt="" style={{ width: "100%", maxHeight: "100%", marginBottom:"100px" }} />
            </div>
        </div>
    );
}

export default Section5;