import React from 'react';
import { LINKS } from './MenuLinks'

function Menu(props) {

    

    return (
        <div className='newacy-navbar'>
            <div className='container'>
                <div className='row'>
                    <div className='col-4 col-md-2 '>
                        <a href="/#">
                            <img src={props.logo} alt="" />
                        </a>
                    </div>
                    <div className='col-8 col-md-10'>
                        <div className='newacy-navbar-wrap'>
                            {LINKS.map(item => item).reverse().map((link, i) => {
                                return (
                                    <div
                                        key={`navbar-link-${i}`}
                                        className='newacy-navbar-wrap-link-box'>
                                        <a
                                            className={link.background ? 'newacy-navbar-link-bg' : ''}
                                            target={link.target}
                                            href={link.href}>{link.label}</a>
                                    </div>
                                )
                            })}

                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default Menu;