import React from 'react';
import Navbar from '../mainComponents/NavBar/Navbar'
import Contact from '../mainComponents/Contact/Contact'
import Footer from '../mainComponents/Footer/Footer'
import './styles/general.css'
function ContactUs(props) {
    return (
        <>
            <Navbar />
            <div className='newacy-contact-banner'>
                <img sizes="(max-width: 1400px) 100vw, 1400px" srcSet="
/images/contact/background_tstldu_c_scale,w_200.jpg 200w,
/images/contact/background_tstldu_c_scale,w_522.jpg 522w,
/images/contact/background_tstldu_c_scale,w_776.jpg 776w,
/images/contact/background_tstldu_c_scale,w_974.jpg 974w,
/images/contact/background_tstldu_c_scale,w_1020.jpg 1020w,
/images/contact/background_tstldu_c_scale,w_1163.jpg 1163w,
/images/contact/background_tstldu_c_scale,w_1299.jpg 1299w,
/images/contact/background_tstldu_c_scale,w_1389.jpg 1389w,
/images/contact/background_tstldu_c_scale,w_1400.jpg 1400w" src="/images/contact/background_tstldu_c_scale,w_1400.jpg" alt="" />

                <h1 className='newacy-contact-banner-title'>{props.translate('contact.page.banner.title')} </h1>
            </div>
            <Contact {...props} />
            <Footer />

        </>
    );
}

export default ContactUs;