import React from 'react';
import { Markup } from 'interweave';

function Card(props) {
    return (
        <div className='newacy-section3-card-wrapper'>
            <div className='newacy-section3-card col-12'>
                <div className='newacy-section3-card-text-wrap '>
                    <div className='newacy-section3-card-title'>
                        <h3>
                            {props.title}
                        </h3>
                    </div>
                    <div className='newacy-section3-card-subtitle'>
                        <Markup content={props.description} />;
                    </div>
                </div>
    
                <div className='newacy-section3-card-image-wrap'>
                    <img src={props.icon} alt="" />
                </div>
    
    
    
            </div>
        </div>
    );
}

export default Card;