import React from 'react';
import './styles/mobile.css'
import './styles/desktop.css'
import NavMenu from './components/NavMenu'
import HamburgerMenu from './components/HamburgerMenu'

function NavBar(props) {
    const LogoThemes = {
        default: '/logos/newacy.svg',
        white: '/logos/newacy-white.svg',
        blue: '/logos/newacy-blue.svg',
        red: '/logos/newacy-red.svg',

    }




    return (
        <>
            <NavMenu logo={LogoThemes[props.logoTheme] || LogoThemes.white} />
            <HamburgerMenu logo={LogoThemes[props.logoTheme] || LogoThemes.white} openLogo={ LogoThemes.white}/>
        </>

    );
}

export default NavBar;