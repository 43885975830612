import React, { useState } from 'react';
import { Markup } from 'interweave';


function Cards(props) {
    const [touched, setTouched] = useState(false);
    return (
        <div className={`newacy-section1-card${props.theme ? `-${props.theme}` : ''}`}>
            <div onClick={() => { setTouched(true) }} className={touched ? '' : 'newacy-touch-suggest'}>
                <div className='newacy-section1-card-icon'>
                    <img src={props.icon} alt="" />
                    <div className='newacy-section1-card-title'>
                        <h4>{props.title}</h4>
                    </div>
                </div>
                <div className='newacy-section1-card-description'>
                    <p>
                        <Markup content={props.description} />
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Cards;