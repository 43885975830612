import React from 'react';
import Navbar from '../mainComponents/NavBar/Navbar'
import Banner from './components/Banner/Banner'
import Section1 from './components/Section1/Section1'
import Section2 from './components/Section2/Section2'
import Section3 from './components/Section3/Section3'
import Section4 from './components/Section4/Section4'
import Section5 from './components/Section5/Section5'
import Contact from '../mainComponents/Contact/Contact'
import Footer from  '../mainComponents/Footer/Footer'

function Home(props) {


    return (
        <>
            <Navbar {...props} />
            <Banner {...props} />
            <Section1 {...props} />

            <Section2 {...props} />
            <Section3 {...props} />
            <Section4 {...props} />
            <Section5 {...props} />
            <Contact {...props}  />

            <Footer /> 
            
        </>
    );
}

export default Home;