import React from 'react';
import Card from './components/Card'
import './styles/card.css'
import './styles/general.css'

function Section3(props) {
    return (
        <div className='newacy-section3'>
            <div className='container'>
                <div className='newacy-title-section-title-wrap'>
                    <div className='newacy-section-title'>
                        <h1>{props.translate('home.section3.title')}</h1>

                    </div>
                    <div className='newacy-section-subtitle'>
                        <h5>{props.translate('home.section3.subtitle')}</h5>
                    </div>
                </div>


                <div className='container'>
                    <div className='row'>
                        <div className='col-12 col-lg-4 '>
                            <Card
                                icon='/images/section3/card1.png'
                                title={props.translate('home.section3.card1.title')}
                                description={props.translate('home.section3.card1.description')}
                            />
                        </div>
                        <div className='col-12 col-lg-4'>
                            <Card
                                icon='/images/section3/card2.png'
                                title={props.translate('home.section3.card2.title')}
                                description={props.translate('home.section3.card2.description')}
                                theme={'blue'}
                            />
                        </div>
                        <div className='col-12 col-lg-4'>
                            <Card
                                icon='/images/section3/card3.png'
                                title={props.translate('home.section3.card3.title')}
                                description={props.translate('home.section3.card3.description')}

                            />
                        </div>
                    </div>
                    <div className='row' style={{ margin: "40px 0px" }}>
                        <div className='d-flex justify-content-center'>

                            <div className='col-12 col-md-6 col-lg-4 '>

                                <a href="/contact">
                                    <button className='btn btn-danger btn-block' style={{width:"100%"}}> <h3><b> {props.translate('home.contact.cta.button.text')}</b> </h3></button>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default Section3;