import React, { useState } from 'react';
import { LINKS } from './MenuLinks'

function HamburgerMenu(props) {
    const [state, setState] = useState({
        menuClosed: true,
    })

    function toggleMenu(value) {
        setState({ ...state, menuClosed: !state.menuClosed })
    }

    const MenuIcon = () => {
        return (
            <svg onClick={() => { toggleMenu() }}
                fill='#fff' viewBox="0 0 100 100" width="40" height="100%">
                <rect y="calc(50% - 40px)" width="100" height="10px"></rect>
                <rect y="calc(50% - 10px)" width="100" height="10px"></rect>
                <rect y="calc(50% + 20px)" width="100" height="10px"></rect>
            </svg>
        )
    }

    const CloseIcon = () => {
        return (
            <svg onClick={() => { toggleMenu() }}
                width="30"
                height="30"
                viewBox="0 0 30 30"
                overflow="visible"
                stroke="#fff"
                stroke-width="4"
                stroke-linecap="round">
                <line y="10" x2="30" y2="30" />
                <line y="10" x1="30" y2="30" />
            </svg>
        )
    }



    const ClosedBar = () => {

        return (
            <div className='hamburger-menu-bar-wrap'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-6 hamburger-menu-logo-container'>
                            <a href='/#' alt='newacy'>
                                <img src={props.logo} alt="newacy" />
                            </a>
                        </div>
                        <div className='col-6 hamburger-menu-icon-container'>
                            <MenuIcon />
                        </div>
                    </div>
                </div>

            </div>
        )

    }

    const MenuBody = () => {
        return (
            <div className='hamburger-menu-body'>
                <div className='hamburger-menu-body-content'>
                    <div className='container'>
                        <div className='row' style={{ padding: "15px" }}>

                            <div className='col-6 hamburger-menu-logo-container'>
                                <a href='/#' alt='newacy'>
                                    <img src={props.openLogo} alt="newacy" />
                                </a>
                            </div>
                            <div className='col-6 hamburger-menu-icon-container'>
                                <CloseIcon />
                            </div>
                        </div>

                    </div>
                    <div className='container'>
                        <div className='hamburger-menu-links'>
                            {LINKS.map((link, i) => {
                                return <div
                                    key={`hamburger-menu-link-${i}`}
                                    className={link.background ? 'hamburger-menu-link-box-white' : 'hamburger-menu-link-box'}>
                                    <a href={link.href} target={link.target} >  {link.label}</a>
                                </div>
                            })}

                        </div>

                    </div>
                </div>

            </div>
        )
    }


    return state.menuClosed ? <ClosedBar /> : <MenuBody />






}

export default HamburgerMenu;