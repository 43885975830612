import React from 'react';
import './styles/general.css'

function Section4(props) {
    return (
        <div className='newacy-section4'>
            <div className='newacy-section4-title'>
                <h1>{props.translate('home.section4.title')}</h1>
            </div>

            <div className='container'>
                <div className='row' style={{padding:'30px'}}>
                    <div className='col newacy-section-4-tools-icon'>
                        <img src="/images/section4/tool1.png" alt="" />
                    </div>
                    <div className='col newacy-section-4-tools-icon'>
                        <img src="/images/section4/tool2.png" alt="" />
                    </div>
                    <div className='col newacy-section-4-tools-icon'>
                        <img src="/images/section4/tool3.png" alt="" />
                    </div>
                    <div className='col newacy-section-4-tools-icon'>
                        <img src="/images/section4/tool4.png" alt="" />
                    </div>
                    <div className='col newacy-section-4-tools-icon'>
                        <img src="/images/section4/tool5.png" alt="" />
                    </div>
                    <div className='col newacy-section-4-tools-icon'>
                        <img src="/images/section4/tool6.png" alt="" />
                    </div>
                    <div className='col newacy-section-4-tools-icon'>
                        <img src="/images/section4/tool7.png" alt="" />
                    </div>
                    <div className='col newacy-section-4-tools-icon'>
                        <img src="/images/section4/tool8.png" alt="" />
                    </div>
                    <div className='col newacy-section-4-tools-icon'>
                        <img src="/images/section4/tool9.png" alt="" />
                    </div>
                </div>
            </div>

        </div>
    );
}

export default Section4;