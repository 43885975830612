import React, { createContext } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import "./styles/default-color.css";
import "./styles/main.css";
import "./styles/default-mobile.css";
import "./styles/default-desktop.css";



// pages  
import Routes from '../pages/routes'



export const AppContext = createContext();
function App(props) {
  const [appState, setAppState] = React.useState({
  });

  const base = "/:locale(en)?";
  return (
    <Router>
      <AppContext.Provider value={{ appState, setAppState }}>
          <Route exact path={`${base}/`} component={Routes} />
          <Route exact path={`${base}/:content`} component={Routes} />
          <Route exact path={`${base}/:content/:subContent`} component={Routes} />
      </AppContext.Provider>
    </Router>
  );
}

export default App;
