import React from 'react';
import Card from './components/card'
import './styles/general.css'
import './styles/card.css'


function Section2(props) {
    return (
        <div className='newacy-section2'>
            <div className='container'>
               
                <div className='newacy-title-section-title-wrap-section-2'>
                    <div className='newacy-section-title'>
                        <h1>{props.translate('home.section2.title')}</h1>

                    </div>
                    <div className='newacy-section-subtitle'>
                        <h5>{props.translate('home.section2.subtitle')}</h5>
                    </div>
                </div>


                <div>

                    <Card
                        icon='/images/section2/card1.jpg'
                        title={props.translate('home.section2.card1.title')}
                        description={props.translate('home.section2.card1.description')}
                    />
                    <Card
                        icon='/images/section2/card2.jpg'
                        title={props.translate('home.section2.card2.title')}
                        description={props.translate('home.section2.card2.description')}
                        sideRight
                    />
                    <Card
                        icon='/images/section2/card3.jpg'
                        title={props.translate('home.section2.card3.title')}
                        description={props.translate('home.section2.card3.description')}
                    />
                </div>
                <div className='d-flex justify-content-center'>
                    <div className='col-6 col-md-4 col-lg-2'>
                        <img src="/logos/newacy.svg" width='100%' alt="" />
                    </div>
                </div>
            </div>

        </div>
    );
}

export default Section2;